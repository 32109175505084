import { React, useEffect, useState } from "react";
import axios from 'axios';
import Guiadisplay from "./Guiadisplay";
import ServiceSelectorModal from "./ServiceSelectorModal";
import Calendardisp from "./Calendardisp";
import { Modal } from "react-bootstrap";


export default function Navbar() {
    const [guias, setGuias] = useState([])
    const [guia, setGuia] = useState('')
    const [especialidades, setEspecialidades] = useState('')
    const [showCal, setShowCal] = useState(false)
    const [selectedGuia, setSelectedGuia] = useState(null)

    const recibeCerrar = (c) => {
        setShowCal(c)
    }
    const recibeSelectedGuia = (g) => {
        setSelectedGuia(g)
        setShowCal(true)
        setGuia(g)
    }
    const getGuias = async () => {
        await axios.get('https://calendario.caminantes.cl/guias').then((response => {
            const guias = response.data.data
            setGuias(guias);
            setEspecialidades([...new Set(guias.map((item) => item.SERVICIO))]);
        }))

    }



    useEffect(() => {
        getGuias()
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <Modal show={showCal} >
                <Calendardisp codguia={guia} selectedGuia={selectedGuia} funcion={recibeCerrar} />
            </Modal>
            <div id="container" className="  w-full andres pt-1 px-1">
                <div id="tres-cols" className="flex flex-col lg:flex-row  w-full grow  pt-1 pb-2 gap-2">
                    <div id="dos-cols" className="flex flex-col lg:w-2/3 lg:flex-row-reverse gap-2">
                        <div id="cabecera" className="andres lg:w-1/2 rounded-md border-2 border-white">
                            <div className=" flex flex-col rounded-md">
                                <div id="titulo" className="flex-1 texto-sombra text-amber-300 text-5xl text-center font-extrabold pt-2">
                                    {'CaminAntes'}
                                </div>
                                <div id="bajada" className="flex-1 texto-sombra text-white text-3xl text-center font-extrabold pt-2">
                                    {'Autismo y Neurodivergencia'}
                                </div>
                                <div id="logo" className="flex-1 pt-2 w-40 mx-auto"
                                    onDoubleClick={() => { window.open('https://www.caminantes.cl/centro') }}>
                                    <img id="logo" alt="logo" src="./reforma4.png"></img>
                                </div>
                                <div id="mapa" className=" animate-pulse lg:text-2xl pt-2 w-fit mx-auto text-slate-100 
                                    cursor-pointer font-bold rounded-lg "
                                    onClick={() => {
                                        window.open('https://www.google.com/maps/place/Centro+de+Terapias+CaminAntes+y+Escuela+Caminar+Libre+CaminAntes/@-33.519091,-70.5715382,17z/data=!4m6!3m5!1s0x9662d141a7703a9b:0x353b4d3dcde274b5!8m2!3d-33.519091!4d-70.5689633!16s%2Fg%2F11vdgr75qh?entry=ttu&g_ep=EgoyMDI1MDIyNi4xIKXMDSoASAFQAw%3D%3D')
                                    }}>
                                    {'📍 Alicahue 7290, La Florida, Santiago'}
                                </div>


                                <div id="botones-land"
                                    className="px-2 flex lg:flex-row flex-col  gap-2 mx-auto pb-2 text-white 
                                texto-sombra font-semibold pt-8 lg:pt-14 text-center">
                                    <div id="boton-reservas" className=" ">
                                        <ServiceSelectorModal datos={guias} funcion={recibeSelectedGuia} showcal={showCal} />
                                    </div>
                                    <div id="Whatsapp" >
                                        <div className="w-52">
                                            <a target="_blank"
                                                href="https://wa.me/56975114334?text=Hola Dolores, mi nombre es: " rel="noreferrer">
                                                <img className="h-fit  mx-auto border-2 rounded-lg" src="./Whatsapp.png" alt="wap" ></img>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="unidades" className=" andres rounded-md  h-full lg:w-1/2 border-2 border-white">
                            <div className="text-center mx-auto pt-1">
                                <div className='texto-sombra text-amber-300 text-4xl font-extrabold pt-2 pb-4'>
                                    {'Somos'}
                                </div>
                                <div id="lineas-de-trabajo" className='texto-sombra text-center w-full rounded  lg:pt-4'>
                                    <div id="unidades" className="flex flex-col lg:gap-8 px-2 w-full texto-sombra rounded-md text-white text-left text-4xl font-extrabold">
                                        <div className="pb-3">
                                            {'👩🏻‍⚕️ Centro de Terapias'}
                                        </div>
                                        <div className="pb-3">
                                            {'📚 Escuela Terapéutica'}
                                        </div>
                                        <div className="cursor-pointer pb-5" onClick={() => { window.open('https://www.universitea.cl/') }}>
                                            {'🎓 Capacitación'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="especialidades" className="pt-2 andres rounded-md lg:w-1/3 border-2 border-white">
                        <div id='lista-esp'
                            className="px-2 mx-auto texto-sombra ">
                            <div className='texto-sombra text-amber-300 text-4xl pb-2 text-center font-extrabold '>
                                {'Especialidades'}
                            </div>
                            <div id="lista-especialidades" className="py-1">
                                {
                                    especialidades && especialidades.map((esp, index) => {
                                        return (
                                            <div key={index} value={esp}
                                                className="flex flex-row gap-4 h-12 font-semibold text-2xl px-3 text-white text-left">
                                                {esp}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div id="equipo" className="w-full border-2 border-white rounded-md">
                    <div className="mx-auto rounded-md ">
                        <div className=" mx-auto text-amber-300 text-4xl text-center font-extrabold texto-sombra">
                            {'Nuestro Equipo'}
                        </div>
                        <div id="card-guias" className="pt-2 px-1 flex flex-wrap">
                            {guias && guias
                                .map((g, index) => {
                                    return (

                                        <div className="mx-auto  flex" key={index}>
                                            <div className=" h-80 py-1">
                                                <Guiadisplay guia={g} funcion={recibeSelectedGuia} />
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

