import React, { useState } from "react";
import { Modal, Button, Dropdown, DropdownButton, ButtonGroup } from "react-bootstrap";

const ServiceSelectorModal = (params) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [selectedGuide, setSelectedGuide] = useState(null);
    const servicesData = params.datos
    // Extract unique services

    const services = [...new Set(servicesData.map((item) => item.SERVICIO))];

    // Filter guides based on selected service
    const guides = 
        selectedService? servicesData.filter((item) => item.SERVICIO === selectedService)
        : [];


    const handleClose = () => {
        setShowModal(false);
        setSelectedGuide(null)
        setSelectedService('')
    }
    const enviaSelected = (d) => {
        const objeto =  servicesData.filter(item => item.CODGUIA === Number(d))[0];
        params.funcion(objeto)
    }
    const handleShow = () => setShowModal(true);
    
    return (
        <>
            {/* Button to open the modal */}
            <Button onClick={handleShow} id="selSolicitud"
                className=" text-black fs-5 fw-bold
                     w-52 h-12 border-2 border-white bg-warning">
                Reserva de Horas
            </Button>
            <Modal show={showModal} onHide={handleClose} 
                className=" pt-2 text-center mx-auto  border-2 border-black text-white">
                <Modal.Header className="andres text-center border-none" closeButton>
                    <Modal.Title className=" py-2 mx-auto  text-white px-2 rounded-md font-bold text-2xl">
                        Solicitud y Reserva de Hora de Terapia CaminAntes
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="andres mx-auto w-full">
                    <DropdownButton
                        variant="warning lg:text-xl text-lg fw-bold border-black"
                        size=""
                        as={ButtonGroup}
                        title={selectedService || "Seleccione una Especialidad"}
                        onSelect={(eventKey) => {
                            setSelectedService(eventKey);
                            setSelectedGuide('');
                        }}>
                        {services.map((service, index) => (
                            <Dropdown.Item key={index}
                                eventKey={service}
                                className="text-left font-bold text-xl">
                                {service}
                            </Dropdown.Item>

                        ))}
                    </DropdownButton>
                    <div className="andres pt-4 ">
                        {selectedService && (
                            <DropdownButton
                                variant="warning lg:text-xl text-lg fw-bold  border-black"
                                className=""
                                size=""
                                as={ButtonGroup}
                                title={selectedGuide.guia || "Selecccione Profesional"}
                                onSelect={(eventKey) => {
                                    enviaSelected(eventKey)
                                    setSelectedService('')
                                    setShowModal(false)
                                }
                                }>
                                {guides.map((guide, index) => (
                                    <Dropdown.Item key={index}
                                        eventKey={guide.CODGUIA}
                                        className="text-left font-extrabold text-xl">{guide.NOMGUIA}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer className="andres border-0">
                    
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ServiceSelectorModal;