// import Navbar from "./modules/Navbar";
import Navbar from "./modules/Navbar";
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
<Navbar/>
    
  );
}
export default App;
